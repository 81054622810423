// @flow
import { DefaultTheme } from 'styled-components';

const mainTheme: DefaultTheme = {
  spacing: {
    containerPadding: '20px',
  },
  colors: {
    lighterBlue: '#1a6aff',
    boldBlue: '#124ab2',
    shadesBlue: '#5F97FF',
    mainBlue: '#3e75c8',
    mainFontColor: '#16183a',
    mutedColor: '#454b60',
    neutralBlack: '#222222',
    lightGray: '#c4c4c4',
    grayNeutralLight: '#595760',
    darkCharcoal: '#333333',
    superSilver: '#EEEEEE',
    gullGrey: '#84858b',
    lighterRed: '#dc3545',
    coralPink: '#FF8591',
    black: '#000000',
    white: '#ffffff',
    lightSilver: '#dcdcdc',
    green: '#218838',
    shadesYellow: '#EFDCAD',
    lightBlue: '#E3EDFF',
    orange: '#E59722',
  },
};

export default mainTheme;

