import moment from 'moment';
import _padStart from 'lodash/padStart';

const format = (date, formatStr) => {
  return moment.isMoment(date) ? 
    date.format(formatStr ? formatStr : 'YYYY-MM-DD')
    : moment(date).format(formatStr ? formatStr : 'YYYY-MM-DD');
};

const today = () => moment().format('YYYY-MM-DD');

const now = () => moment().format('YYYY-MM-DD HH:mm:ss');

const formatDateTime = date => format(date, 'YYYY-MM-DD HH:mm:ssA');

const getNowDateString = () => {
  const date = new Date();
  const year: string = date.getFullYear().toString().substring(2);
  const month: number = date.getMonth() + 1;
  const day: number = date.getDate();

  return `${_padStart(month, 2, '0')}/${_padStart(day, 2, '0')}/${year}`;
};

const DateUtil = {
  format,
  today,
  now,
  formatDateTime,
  getNowDateString,
};

export default DateUtil;
