import React, { Component } from 'react';
import _ from 'lodash';
import { Link, NavLink } from 'react-router-dom';

import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import NavDropdown from 'react-bootstrap/NavDropdown';

import Logout from '../components/auth/Logout';

const NEWSLETTER_MENU = [
  {
    label: 'Original',
    link: 'inkbox/original',
  },
  {
    label: 'Alert',
    link: 'inkbox/alert',
  },
  {
    label: 'Curated',
    link: 'inkbox/curated',
  },
];

const NEWSLETTER_V2_MENU = [
  {
    label: 'Original',
    link: 'inkbox-v2/original',
  },
  {
    label: 'Alert',
    link: 'inkbox-v2/alert',
  },
  {
    label: 'Curated',
    link: 'inkbox-v2/curated',
  },
  {
    label: 'Ads',
    link: 'inkbox-v2/ads',
  }
];

const MORE_MENU = [
  {
    label: 'Swipeable',
    link: 'swipeable'
  },
  {
    label: 'Blog',
    link: 'ink-blog'
  },
  {
    label: 'Curation',
    link: 'curations',
  },
  {
    label: 'Active Sections',
    link: 'sections',
  },
  {
    label: 'Demo Articles',
    link: 'demo-articles',
  },
  {
    label: 'Fusion Settings',
    link: 'fusion-settings'
  },
  {
    label: 'Live Activities QA',
    link: 'live-activities'
  },
];

const SETTINGS = {
  label: 'Settings',
  link: 'settings',
}

const TABS = [
  {
    label: 'Push',
    link: 'notifications',
    menus: [],
  },
  {
    label: 'Newsletters',
    link: 'newsletters',
    menus: NEWSLETTER_MENU,
    customCss: 'd-none'
  },
  {
    label: 'Newsletters',
    link: 'newsletters-v2',
    menus: NEWSLETTER_V2_MENU
  },
  {
    label: 'More',
    link: 'more',
    menus: MORE_MENU,
  },
];

class Header extends Component {

  render() {
    const checkTab = this._checkPath();

    return (
      <Navbar bg="white" className='nav-bar'>
        <Navbar.Brand>
          <NavLink to="/home">
            <img
              src="/images/ink@3x.png"
              className="App-logo"
              alt="Ink. logo"
            />
            <span className="App-logo-text">Ink.</span>
          </NavLink>
        </Navbar.Brand>
        <span className="App-header-divider"></span>
        <div className='menu-wrapper'>
        <Nav className={`App-tab-link ${checkTab(TABS[0].link)}`} >
          <NavLink className='menu-link' to={'/' + TABS[0].link}>{TABS[0].label}</NavLink>
          <div className="hl"></div>
        </Nav>
        {
          _.map(_.drop(TABS), (tab) => (
            <Nav key={tab.link} className={`App-tab-link ${checkTab(tab.link)} ${tab?.customCss}`}>
              <NavDropdown
                title={tab.label}
                className='menu-link'
              >
                {
                  _.map(tab.menus, item => 
                    <NavDropdown.Item
                      className='menu-link-dropdown py-0 border-0'
                      active={checkTab(item.link) === 'selected'}
                      key={item.link}
                    >
                      <Link className={`w-100 py-2 menu-link-dropdown ${checkTab(item.link)}`} to={`/${item.link}`}>{item.label}</Link>
                    </NavDropdown.Item>
                  )
                }
              </NavDropdown>
            </Nav>
          ))
        }
        </div>
        <div className='w-100 h-100 d-flex justify-content-end'>
          <div className='d-flex'>
            <Nav className={`App-tab-link mr-4 ${checkTab(SETTINGS.link)}`} >
              <NavLink className='menu-link' to={`/${SETTINGS.link}`}>{SETTINGS.label}</NavLink>
              <div className='hl'></div>
            </Nav>
            <Navbar.Collapse className='user-area'>
              <Logout />
            </Navbar.Collapse>
          </div>
        </div>
      </Navbar>
    );
  }

  _checkPath() {
    const currentTab = _.chain(this.props).get('path', '/').split('/').value()[1];
    const isDropdown = _.isEmpty(MORE_MENU.find(item => item.link === currentTab)) === false;

    return tab => currentTab === tab || (tab === 'more' && isDropdown) || (tab === currentTab && isDropdown) ? 'selected' : '';
  }
}

export default Header;
