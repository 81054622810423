import {
  postData,
  queryGraphQL,
  getData,
  getDataNoAuth,
  putData,
  deleteData,
  fetchApi,
} from '../utils/fetch-api';
import _flatMap from 'lodash/flatMap';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import _omit from 'lodash/omit';
import _merge from 'lodash/merge';
import { inqRandom } from '../components/inkblog/services/random-id';
import { EditorState, convertFromRaw } from 'draft-js';
import { getHtmlText, punycodeUcs2Decode } from '../utils/string-helper';

const baseUrl = `${process.env.REACT_APP_API_URL}`;
const baseSlsUrl = `${process.env.REACT_APP_BASE_API_URL}`;

function getStoriesByIds(ids) {
  return postData(`${baseUrl}/stories/ids`, ids);
}

function getAutoCuratedStories() {
  return getData(`${baseSlsUrl}feeds/rest/homepage-feeds`, false);
}

function getCuratedStories() {
  const query = `
    query {
      feeds {
        top_stories {
          id
          title
          image_url
          author
        }
      }
    }
  `;

  return queryGraphQL(`${baseSlsUrl}feeds/graphql`, query);
}

function searchStoriesByGraphQL(q, size = 20) {
  const query = `
    query {
      searchPublishedStories(query: "${q}", options:{sort: "display_date:desc", maxRelevantSize:${size}}) {
        id
        title
        item_url
        synopsis
        image_url
        author
        subtype
        cropInfo
        article_tags
        creditsBasic
        taxonomy {
          primary_section
        }
      }
    }
  `;

  return postData(`${baseSlsUrl}site-search/graphql`, {query});
}

const getDemoArticles = () => {
  const query = 'query {getDemoArticleData}';

  return postData(`${baseSlsUrl}stories/graphql`, {query});
};

const addDemoArticles = (ids) => {
  const query = `
    mutation addDemoArticles ($ids: [String]) {
      upsertDemoArticleData(articleIds: $ids)
    }
  `;

  const variables = { ids };

  return queryGraphQL(`${baseSlsUrl}stories/graphql`, query, variables);
};

const getStoryById = (id, query) => {
  const queryExe = query || `
    query ($id: String!, $retryWithUnpublished: Boolean) {
      getStory(id: $id, retryWithUnpublished: $retryWithUnpublished) {
        article {
          title
          item_url
          image_url
          image_credit
          subtype
          content_elements {
            content
          }
        }
      }
    }`;

    return queryGraphQL(`${baseSlsUrl}stories/graphql`, queryExe, { id, retryWithUnpublished: true });
};

const getArticlesByIds = (ids) => {
  const query = `
    query ($ids: String!) {
      getStories(ids: $ids) {
        articles {
          id
          image_url
          headline
          item_url
          taxonomy {
            primary_section
          }
          article_tags
        }
      }
    }`;

  const variables = { ids };

  return queryGraphQL(`${baseSlsUrl}stories/graphql`, query, variables);
};

const _getSearchUrl = (size = 10, keyword: '') => {
  return [
    `${baseSlsUrl}arc-content/arc-proxy/content/v4/search/?size=${size}&sort=last_updated_date:desc&website=philly-media-network&_sourceInclude=headlines.basic,promo_items.basic.url,canonical_url,display_date,last_updated_date&q=NOT (taxonomy.sites._id:("/zzz-systest")) AND type:story AND subtype:"subtype-live" AND (NOT subtype:"subtype-live-event")`,
    keyword.length > 0 ? `headlines.basic:"${keyword}"` : ''
  ].filter(item => item).join(' AND ');
}

const _getSearchUrlSwipeable = (size = 10, keyword: '') => {
  return [
    `${baseSlsUrl}arc-content/arc-proxy/content/v4/search/?size=${size}&sort=last_updated_date:desc&website=philly-media-network&_sourceInclude=headlines.basic,promo_items.basic.url,canonical_url,display_date,last_updated_date&q=NOT (taxonomy.sites._id:("/zzz-systest")) AND type:story AND subtype:"subtype-swipeable" AND (NOT subtype:"subtype-live") AND (NOT subtype:"subtype-live-event")`,
    keyword.length > 0 ? `headlines.basic:"${keyword}"` : ''
  ].filter(item => item).join(' AND ');
}

const searchAllLiveBlog = (size = 10) => {
  return getDataNoAuth(_getSearchUrl(size, ''));
}

const searchAllSwipeable = (size = 10) => {
  return getDataNoAuth(_getSearchUrlSwipeable(size, ''));
}

const searchArticleByHeadline = (keyword) => {
  return getDataNoAuth(_getSearchUrl(75, keyword));
}

const _listRevision = async (id) => {
  try {
  const { revisions } = await getDataNoAuth(`${baseSlsUrl}arc-content/arc-proxy/draft/v1/story/${id}/revision`);

    if (!revisions || !revisions.length) return '';

    revisions.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));

    return revisions[0].id;
  } catch (error) {
    console.error("Error fetching or processing data:", error);
    return '';
}
};

const getArcArticleById = async (id) => {
  const revisionId = await _listRevision(id);
  const data = await getDataNoAuth(`${baseSlsUrl}arc-content/arc-proxy/draft/v1/story/${id}/revision/${revisionId}`);
  const { ans, document_id } = data;
  const contentElements = ans?.content_elements?.map(item => {
    const randomId = inqRandom();

    return {
      ...item,
      _id: randomId,
      ...item?.additional_properties && { additional_properties: {
        ...item.additional_properties,
        _id: randomId,
      }}
    };
  });

  return { ...ans, _id: document_id, content_elements: contentElements };
}

const _getSearchPhotoUrl = (keyword, type = 'photo', size = 50, page = 1) => (
  `${baseSlsUrl}arc-content/search-photo-video?limit=${size}&page=${page}&type=${type}${keyword?.length > 0 ? `&searchTerm=${keyword}` : ''}`
)

const searchPhoto = (keyword, size = 50, page = 1) => {
  return getDataNoAuth(_getSearchPhotoUrl(keyword, 'photo', size, page));
}

const searchVideo = async (keyword, size = 10, page = 1) => {
  const response = await getDataNoAuth(_getSearchPhotoUrl(keyword, 'video', size, page));

  return response?.records || [];
}

const searchGallery = (keyword, size = 50, page = 1) => (
  getDataNoAuth(_getSearchPhotoUrl(keyword, 'gallery', size, page))
)

const getCommissionTeam = (byline) => getDataNoAuth(`${baseSlsUrl}airtable/get-commission-team`)

const getAuthorBySearch = (byline) => getDataNoAuth(`${baseSlsUrl}airtable/search-authors?byline=${byline}`)

const getSiteSections = (byline) => getDataNoAuth(`${baseSlsUrl}airtable/get-site-sections`)

const getFullContentArticleById = (id) =>
  getDataNoAuth(`${baseSlsUrl}arc-content/arc-proxy/content/v4/?_id=${id}&website=philly-media-network&published=false`)

const getGalleryElements = (galleryId) =>
  getDataNoAuth(`${baseSlsUrl}arc-content/arc-proxy/photo/api/v2/galleries/${galleryId}`)

const fieldsToExcludeInDraftUpdate = [
  'websites',
  'canonical_url',
  'taxonomy.sections',
  'taxonomy.sites',
  'taxonomy.primary_section',
  'taxonomy.primary_site',
  'inkCards',
  'canonical_url',
  'publish_date',
];

const splitParagraphs = (element) => {
  const paragraphs = element?.content?.split(/(<p[^>]*>.*?<\/p>)/g);

  if (paragraphs?.length > 1) {
    return paragraphs?.map((value) => ({
      ...element,
      content: value,
      _id: inqRandom(),
    }))?.filter(item => item?.content);
  }

  return [element];
};

const getStoryLength = (contentElements) => {
  if (!contentElements?.length) {
    return {
      character_count_actual: 0,
      character_encoding: "UTF-16",
      inch_count_actual: 0,
      line_count_actual: 0,
      word_count_actual: 0,
    };
  }

  const blocks = contentElements.reduce((acc, element) => {
    const {_id, type, content, items} = element;
   
    if (['header', 'text'].includes(type)) {
      return [...acc, {
        key: _id,
        text: getHtmlText(content),
        type: type === 'header' ? 'header-two' : 'unstyled',
      }];
    }

    if (type === 'list') {
      const list = items.map(({ _id, content }) => {
        return {
          key: _id,
          text: getHtmlText(content),
          type: "unordered-list-item",
        }
      });

      return [...acc, ...list]
    }

    return acc;
  }, []);

  const editorState = EditorState.createWithContent(
    convertFromRaw({
      blocks: blocks,
      entityMap: {},
    })
  );

  const plainText = editorState.getCurrentContent().getPlainText('');
  const blockArray = editorState.getCurrentContent().getBlocksAsArray();
  const regex = /(?:\r\n|\r|\n)/g;
  const wordArray = plainText.replace(regex, ' ').trim().match(/\S+/g);
  const characters = punycodeUcs2Decode(plainText.replace(regex, '').trim()).length;
  const lines = blockArray?.length || 0;
  const linesPerInch = 7;

  return {
    character_count_actual: characters || 0,
    character_encoding: "UTF-16",
    inch_count_actual: Math.ceil(lines / linesPerInch),
    line_count_actual: lines,
    word_count_actual: wordArray?.length || 0,
  };
}

const updateImagesInGallery = async (id, photos) => {
  const data = await getGalleryElements(id);
  const galleryVersion = _get(data, 'additional_properties.version', 0);
  const imageCoverId = _get(data, 'promo_items.basic._id', '');

  photos = photos.map(photo => ({
    ...photo,
    galleryVersion,
    cover: imageCoverId === photo._id,
  }));

  return putData(`${baseSlsUrl}arc-content/arc-proxy/photo/api/v2/galleries/${id}/photos`, photos, true);
}

const saveDraftArcArticleById = async (id, article) => {
  const cards = [...article?.inkCards].map((inkCard, index) => {
    const card = { ...inkCard };
    const hasPinnedElement = card?.elements?.find(element => element?.content?.includes('// Pinned'));

    if (!card?.isPinned) {
      hasPinnedElement && card?.elements?.shift();
    } else {
      !hasPinnedElement && card?.elements?.unshift({
        content: '// Pinned',
        type: 'text',
        _id: inqRandom()
      });
    }

    if (index === article?.inkCards?.length - 1) {
      return card;
    }

    const divider = {
      _id: inqRandom(),
      type: 'divider',
    };

    return {
      ...card,
      elements: [...card?.elements, divider],
    };
  });

  const contentElements = _flatMap(cards || [], 'elements')
    .reduce((accumulator, currentObject) => {
      if (currentObject?.type === 'text') {
        const paragraphs = splitParagraphs(currentObject);

        return [...accumulator, ...paragraphs];
      }

      return [...accumulator, currentObject];
    }, [])
    .map((item) => {
      const { _id, type, content, items, url, referent, list_type, additional_properties, level, displayPhotos } = item;

      if (referent && !referent?.id) {
        return false;
      }
      if (type === 'reference' && _isEmpty(displayPhotos) === false) {
        let isUpdate = false;
        const photos = displayPhotos.map((photo, index) => {
          if (_isEmpty(photo.referentCaption) === false && photo.referentCaption !== photo.caption) {
            isUpdate = true;
          }
            return  {
              "_id": photo._id,
              "caption": _get(photo, 'referentCaption', '') || _get(photo, 'caption', ''),
              "focal_point": {"x": 0, "y": 0},
              "subtitle": _get(photo, 'subtitle', ''),
              "versionCounter": _get(photo, 'additional_properties.version', 0)
            }
        });

        if (isUpdate) {
          updateImagesInGallery(_id, photos)
        }
      }

      if (typeof content === 'object' && !content?.content) {
        return false;
      }

      return {
        _id: _id,
        type: type,
        ...content && { content },
        ...items?.length > 0 && { items },
        ...url && { url },
        ...referent && { referent },
        ...list_type && { list_type },
        ...additional_properties && { additional_properties },
        ...level && { level },
      };
    }).filter(item => item);


  const storyLength = getStoryLength(contentElements);

  const arcArticle = await getArcArticleById(id);
  const sourceArticle = {
    ..._omit(arcArticle, ['headlines.basic', 'description', 'content_elements', 'promo_items', 'planning']),
    taxonomy: {
      ...arcArticle?.taxonomy,
      tags: arcArticle?.taxonomy?.tags?.filter(item => item?.slug !== 'endliveblog') || [],
    }
  }
  const mergedArticle = _merge(article, sourceArticle);
  const savedArticle = {
    ans: {
      ..._omit(mergedArticle, fieldsToExcludeInDraftUpdate),
      ..._isEmpty(article?.promo_items) === false && {
          promo_items: {
          ...article?.promo_items,
          basic: {
            _id: article?.promo_items?.basic?._id,
            referent: {
              id: article?.promo_items?.basic?._id,
              provider: "",
              referent_properties: article?.promo_items?.basic?.referent?.referent_properties,
              type: "image"
            },
            type: "reference"
          }
        }
      },
      content_elements: contentElements,
      planning: {
        ...article?.planning,
        ...storyLength && { story_length: storyLength },
      }
    },
  };

  return await putData(`${baseSlsUrl}arc-content/arc-proxy/draft/v1/story/${id}/revision/draft`, savedArticle, true);
};

const saveSwipeableArcArticleById = async (id, article, fullContent) => {
  const arcArticle = await getArcArticleById(id);
  let articleForSave = { ans: { ...article } };
  articleForSave.ans.revision = arcArticle.revision;
  let ans = articleForSave.ans;

  const credits = article?.credits?.by?.map((author) => {
    if (author.type !== "reference") {
      return {
        type: "reference",
        referent: {
          id: author._id,
          type: "author",
          referent_properties: {},
          provider: ""
        }
      }
    } else {
      return author;
    }
  })
  ans = {
    ...ans,
    ...{
      credits: {
        by: credits
      }
    },
    ..._isEmpty(article?.promo_items) === false && {
        promo_items: {
        ...article?.promo_items,
        basic: {
          _id: article?.promo_items?.basic?._id,
          referent: {
            id: article?.promo_items?.basic?._id,
            provider: "",
            referent_properties: {
              caption: article?.promo_items?.basic?.caption || '',
              vanity_credits: {
                by: article?.promo_items?.basic?.credits?.by || []
              }
            },
            type: "image"
          },
          type: "reference"
        }
      }
    }
  };

  if (article?.taxonomy?.primary_section?._id &&
    article?.taxonomy?.primary_section?._id !== arcArticle?.taxonomy?.primary_section?._id) {
      const circs = await getCirculationsByArticleId(id);

      const newSection = circs?.circulations?.[0]?.website_sections?.find((item) => {
        return item?.referent?.id === article?.taxonomy?.primary_section?._id;
      });

      const circulationData = {
        document_id: article._id,
        website_id: "philly-media-network",
        updated_at: new Date().toISOString(),
        website_url: `${article?.taxonomy?.primary_section?._id}/${new Date().toISOString()}.html`,
        website_sections: [
          ...circs?.circulations?.[0]?.website_sections,
          newSection ? {} : {
            type: "reference",
            referent: {
              type: "section",
              id: article?.taxonomy?.primary_section?._id,
              website: "philly-media-network"
            }
          }
        ],
        website_primary_section: {
          type: "reference",
          referent: {
            type: "section",
            id: article?.taxonomy?.primary_section?._id,
            website: "philly-media-network"
          }
        },
      }

      await putData(`${baseSlsUrl}arc-content/arc-proxy/draft/v1/story/${article._id}/circulation/philly-media-network`, circulationData, true).then((res) => {
      console.log(res);
    }).catch((err) => {
      console.log(err);
    })
  }

  ans = _omit(ans, fieldsToExcludeInDraftUpdate);
  delete ans?.revision.editions;
  
  return await putData(`${baseSlsUrl}arc-content/arc-proxy/draft/v1/story/${id}/revision/draft`, { ans }, true);
};

const getCirculationsByArticleId = async(id) => {
  return await getDataNoAuth(`${baseSlsUrl}arc-content/arc-proxy/draft/v1/story/${id}/circulation`);
};

const publishArcArticleById = async (id, article) => {
  await saveDraftArcArticleById(id, article);

  const data = await postData(`${baseSlsUrl}arc-content/arc-proxy/draft/v1/story/${id}/revision/published`, {}, true);
  const { ans, document_id } = data;

  return { ...ans, _id: document_id };
};

const publishSwipeableArcArticleById = async (id, article) => {
  await saveSwipeableArcArticleById(id, article);

  const data = await postData(`${baseSlsUrl}arc-content/arc-proxy/draft/v1/story/${id}/revision/published`, {}, true);
  const { ans, document_id } = data;

  return { ...ans, _id: document_id };
};

const unpublishArcArticleById = async (id) => {
  const data = await deleteData( `${baseSlsUrl}arc-content/arc-proxy/draft/v1/story/${id}/revision/published`, {}, true);
  const { ans, document_id } = data;

  return { ...ans, _id: document_id };
};

const sendAPNS = (articleId, body) => {
  try {
    postData(`${baseSlsUrl}gdc-live/raw-message-event/${articleId}`, body, false, null, true);
  } catch (error) {
    console.log('Error when sending APNS', error);
  }
}

const triggerLiveblogSocket = (id) => {
  try {
    fetchApi(`${process.env.REACT_APP_LIVEBLOG_SOCKET_URL}trigger-update/${id}`, {});
  } catch (error) {
    console.log('Error during triggering socket: ', error)
  }
};

const storyService = {
  getAutoCuratedStories,
  getStoriesByIds,
  getCuratedStories,
  searchStoriesByGraphQL,
  getDemoArticles,
  addDemoArticles,
  getArticlesByIds,
};

export default storyService;

export {
  getStoryById,
  searchAllLiveBlog,
  getArcArticleById,
  searchArticleByHeadline,
  searchPhoto,
  searchGallery,
  getFullContentArticleById,
  getGalleryElements,
  searchVideo,
  publishArcArticleById,
  unpublishArcArticleById,
  saveDraftArcArticleById,
  getCirculationsByArticleId,
  triggerLiveblogSocket,
  sendAPNS,
  searchAllSwipeable,
  getAuthorBySearch,
  getSiteSections,
  getCommissionTeam,
  saveSwipeableArcArticleById,
  publishSwipeableArcArticleById,
};

